#ForgetPassword {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f8fafd;
}
#ForgetPassword .container {
  flex: 1;
  width: 100%;
  padding: 60px 0 90px;
  background: #F9FAFC;
}
#ForgetPassword .container .loginControlForget {
  width: 500px;
  margin: 0 auto;
}
#ForgetPassword .container .loginControlForget .loginTitle {
  text-align: center;
  padding: 30px 0 20px;
  font-size: 34px;
}
#ForgetPassword .container .loginControlForget .el-form-item__label {
  font-size: 17px;
  color: #3f4652;
}
#ForgetPassword .container .loginControlForget .loginButtonForget {
  padding: 1.25rem 0 0;
  display: flex;
  text-decoration: none;
}
#ForgetPassword .container .loginControlForget .loginButtonForget > .el-button {
  width: 100%;
  height: 2.5rem;
}
#ForgetPassword .container .loginControlForget .loginButtonForget .bgc-bv {
  background: #2878FF !important;
  border-color: #2878FF !important;
}
#ForgetPassword .container .loginControlForget .loginButtonForget .bgc-bv:hover {
  background: #2878FF !important;
  border-color: #2878FF !important;
}
#ForgetPassword .container .elForm {
  box-shadow: 0px 6px 50px 1px #eeeeee;
  padding: 40px 40px 10px 40px;
  border-radius: 6px;
}
#ForgetPassword .container .elForm .yzmz {
  position: absolute;
  top: 0;
  right: 20px;
  color: #2878ff;
  font-size: 12px;
  cursor: pointer;
}
